<template>
  <el-container style="height: 100%">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/myFolder' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goBack"
            ><a>{{ deviceName }}</a></el-breadcrumb-item
          >
          <el-breadcrumb-item>多图分析</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-header>

    <el-container style="height: calc(100% - 40px); background: #f4f4f4">
      <el-aside width="48px" class="info-aside">
        <div class="button-line">
          <div class="button-img" v-bind:title="'绘制测温点'" @click="functClick('point')">
            <el-image
              style="width: 100%; height: 100%"
              :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'point' ? 'point_sel' : 'point_def'}.png`)"
              fit="contain"
            ></el-image>
          </div>
          <div class="button-img" v-bind:title="'绘制测温直线'" @click="functClick('line')">
            <el-image
              style="width: 100%; height: 100%"
              :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'line' ? 'line_sel' : 'line_def'}.png`)"
              fit="contain"
            ></el-image>
          </div>
        </div>
        <div class="button-line">
          <div class="button-img" v-bind:title="'绘制测温矩形'" @click="functClick('rectangle')">
            <el-image
              style="width: 100%; height: 100%"
              :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'rectangle' ? 'rectangle_sel' : 'rectangle_def'}.png`)"
              fit="contain"
            ></el-image>
          </div>
          <div class="button-img" v-bind:title="'绘制测温圆形'" @click="functClick('circle')">
            <el-image
              style="width: 100%; height: 100%"
              :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'circle' ? 'circle_sel' : 'circle_def'}.png`)"
              fit="contain"
            ></el-image>
          </div>
        </div>
        <div class="button-line">
          <div class="button-img" v-bind:title="'绘制测温多边形'" @click="functClick('polygon')">
            <el-image
              style="width: 100%; height: 100%"
              :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'polygon' ? 'polygon_sel' : 'polygon_def'}.png`)"
              fit="contain"
            ></el-image>
          </div>
        </div>
      </el-aside>

      <el-aside style="height: 100%; width: 20px; background: #f4f4f4"></el-aside>

      <el-main
        style="position: relative; overflow: hidden; box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2); border-radius: 8px; background: white"
      >
        <!-- <div style="position: absolute; top: 0">
          <span class="back-span" @click="goBack">返回上一级</span>
          <input type="file" id="fileInput" name="file" @change="fileChange" />
        </div> -->

        <div id="main-top">
          <el-tag v-for="(draw, index) in drawList" :key="draw.name" @close="roiDelete(index, draw.name)" closable type="" style="margin-right: 5px">
            {{ draw.name }}
          </el-tag>
        </div>
        <div id="main-img">
          <div class="content" :style="contentStyle">
            <div id="relate-div">
              <canvas
                id="canvas"
                @mousewheel.prevent="mouseWheel"
                @mousemove="mouseMove"
                @mouseout="mouseOut"
                @mousedown="mouseDown"
                @mouseup="mouseUp"
                @contextmenu.prevent="contextMenu"
                @click="mouseClick"
              ></canvas>
            </div>
          </div>
        </div>

        <div id="exportChart" style="width: 1000px; height: 1000px"></div>
      </el-main>

      <el-aside style="height: 100%; width: 20px; background: #f4f4f4"></el-aside>

      <el-aside width="323px" class="info-aside">
        <el-divider content-position="left">温度曲线</el-divider>
        <el-form label-width="100px">
          <el-form-item label="测温区域">
            <el-select v-model="areaName" @change="areaChange" placeholder="选择测温区域">
              <div v-for="item in drawList" :key="item.name">
                <el-option :value="item.name"></el-option>
              </div>
            </el-select>
          </el-form-item>
        </el-form>
        <div id="area-chart" class="aside-chart"></div>

        <el-divider content-position="left">批量导出报告</el-divider>
        <div class="export-btn">
          <el-button type="primary" @click="getSingleWord">批量报告</el-button>
          <el-button type="primary" @click="getCombineWord">对比报告</el-button>
        </div>
      </el-aside>
    </el-container>
  </el-container>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl'
import axios from 'axios'
import { getParams, getPointTemp, getLineTemp, getRctTemp, getCircleTemp, getPolyTemp } from '@/utils/temperature.js'
import { exportWord } from '@/utils/exportWord.js'
import { findStr } from '@/utils'

var jpeg = require('jpeg-js')
var cv = require('opencv.js')

export default {
  name: 'AnalysisMore',
  data() {
    return {
      returnFlag: false,
      isHaveImg: false,
      currentFunct: '',
      params: {}, // 参数信息
      mIRMat: undefined, // 红外
      canvasMat: undefined, // 当前底图
      newMat: undefined, // 最新画布数据
      pTempBufferDst: [], // 16进制的温度数据
      offsetX: 0,
      offsetY: 10,
      fontColor: '#0000ff',
      fontSize: 15,
      tempTypeCheckList: ['max', 'min', 'avg'],
      tempShowLocation: 'nrml',
      pointCount: 1,
      linCount: 1,
      rctCount: 1,
      circleCount: 1,
      polyCount: 1,
      startFlag: false, // 是否开始绘制
      drawList: [], // 图像列表
      polygonXYList: [], // 多边形顶点坐标，{x:1,y:1}
      timeNow: '',
      areaName: '',
      areaChart: undefined, // 区域图表
      pdfObj: {}, // pdf信息
      paramList: [],
      countWatch: 0, // 当前已处理数
      countTotal: 0, // 总数
      roiAndtempList: {}, // 区域对应的温度数据 key: 区域名称 value：文件顺序对应的温度数据
      exportChart: undefined,
      triSize: 4, // 最大最小坐标标记的大小
      zoom: 1, // 放大倍数
      minWidth: '100%', // content的最小宽度 高度
      minHeight: '100%',
      errFlag: false,
      errFileName: '',
      deviceName: ''
    }
  },
  computed: {
    contentStyle() {
      return `min-width: ${this.minWidth}; min-height: ${this.minHeight};`
    }
  },
  watch: {
    // 解决异步问题
    countWatch(val) {
      if (val === this.countTotal) {
        // 尺寸不同
        let breakFlag = false
        for (let j = 0; j < this.paramList.length; j++) {
          if (j !== 0 && (this.paramList[j].iwidth !== this.paramList[0].iwidth || this.paramList[j].iheight !== this.paramList[0].iheight)) {
            this.$alert('图像尺寸不同，不能进行多图分析', '提示', {
              confirmButtonText: '返回',
              type: 'error',
              callback: () => {
                this.goBack()
              }
            })

            breakFlag = true
            break
          }
        }

        if (!breakFlag) {
          this.params = this.paramList[0]
          this.getMat()
        }
      }
    },
    // 解析报错 不同源
    errFlag(val) {
      if (val && !this.returnFlag) {
        this.$alert(`${this.errFileName}文件来源不一致，不能进入多图分析`, '提示', {
          confirmButtonText: '返回',
          type: 'error',
          callback: () => {
            this.goBack()
          }
        })
      }
    }
  },
  mounted() {
    const analysisObjList = JSON.parse(sessionStorage.analysisMore || '[]')

    if (analysisObjList.length) {
      this.deviceName = analysisObjList[0].deviceName
      this.countTotal = analysisObjList.length
      // 解析数据
      const requestList = []
      analysisObjList.forEach((item) => {
        requestList.push(() => {
          const { isIrg, irgUrl, irUrl, dcUrl, fileName } = item

          if (isIrg && irgUrl) {
            // 获取irgList
            return axios
              .get((irgUrl.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + irgUrl.substr(findStr(irgUrl, '/', 2)), {
                responseType: 'blob'
              })
              .then((res) => {
                const blob = new Blob([res.data])
                const reader = new FileReader()
                reader.readAsArrayBuffer(blob)
                reader.onload = (e) => {
                  const list = new Uint8Array(e.target.result)
                  // 整合图像信息
                  try {
                    this.integrateImgInfo(getParams({ isGW: false, irgU8Array: list, fileName }))
                  } catch (error) {
                    this.errFlag = true
                    this.errFileName = fileName
                  }
                }
              })
              .catch(() => {
                this.returnFlag = true
              })
          } else if (!isIrg && irUrl && dcUrl) {
            // 获取dcList irList
            return axios
              .get((irUrl.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + irUrl.substr(findStr(irUrl, '/', 2)), {
                responseType: 'blob'
              })
              .then((res) => {
                const blob = new Blob([res.data])
                const reader = new FileReader()
                reader.readAsArrayBuffer(blob)
                reader.onload = (e) => {
                  const irList = new Uint8Array(e.target.result)

                  axios
                    .get((dcUrl.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + dcUrl.substr(findStr(dcUrl, '/', 2)), {
                      responseType: 'blob'
                    })
                    .then((res) => {
                      const blob = new Blob([res.data])
                      const reader = new FileReader()
                      reader.readAsArrayBuffer(blob)
                      reader.onload = (e) => {
                        const dcList = new Uint8Array(e.target.result)
                        // 整合图像信息
                        try {
                          this.integrateImgInfo(getParams({ isGW: true, irU8Array: irList, dcU8Array: dcList, fileName }))
                        } catch (error) {
                          this.errFlag = true
                          this.errFileName = fileName
                        }
                      }
                    })
                    .catch(() => {
                      this.returnFlag = true
                    })
                }
              })
              .catch(() => {
                this.returnFlag = true
              })
          }
        })
      })

      let i = 0
      const send = async () => {
        // 失败
        if (this.returnFlag && !this.errFlag) {
          this.$alert('获取测温数据失败，不能进行多图分析', '提示', {
            confirmButtonText: '返回',
            type: 'error',
            callback: () => {
              this.goBack()
            }
          })
        }

        // 解析报错
        if (!this.errFlag && i < requestList.length) {
          await requestList[i]()
          i++
          send()
        }
      }

      send()
    } else {
      this.goBack()
    }
  },
  methods: {
    // 删除测温区域
    roiDelete(index, name) {
      this.drawList.splice(index, 1)
      // 删除区域对应的温度数据
      delete this.roiAndtempList[name]
      this.drawRoi()

      // 销毁图表
      if (this.areaName === name) {
        this.areaChart.dispose()
        this.areaName = ''
      }
    },
    // 整合图像信息
    integrateImgInfo(params, importFlag) {
      if (!importFlag) {
        this.paramList.push(params)
        this.countWatch++
      } else {
        this.params = params
        this.getMat()
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 获取Mat
    getMat() {
      // 红外
      // 灰度图
      const img = cv.matFromArray(this.params.iheight, this.params.iwidth, cv.CV_8UC1, this.params.mIRBuffer)
      this.mIRMat = img.clone()
      cv.cvtColor(this.mIRMat, this.mIRMat, cv.COLOR_GRAY2BGR)
      this.canvasMat = this.mIRMat.clone()
      cv.imshow('canvas', this.canvasMat)

      // 温度
      this.pTempBufferDst = this.params.pTempBufferDst
      this.isHaveImg = true
    },
    // 更改测温区域
    areaChange(e) {
      const tempList = this.roiAndtempList[e] || []
      const maxData = []
      const minData = []
      const avgData = []

      tempList.forEach((temp) => {
        maxData.push(temp.max)
        minData.push(temp.min)
        avgData.push(temp.avg)
      })

      if (!this.areaChart || this.areaChart.isDisposed()) {
        this.areaChart = echarts.init(document.getElementById('area-chart'))
      }
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['MAX', 'MIN', 'AVG']
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          },
          top: '-1.5%',
          right: '4.5%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}℃'
          },
          scale: true
        },
        series: [
          {
            name: 'MAX',
            type: 'line',
            data: maxData
          },
          {
            name: 'MIN',
            type: 'line',
            data: minData
          },
          {
            name: 'AVG',
            type: 'line',
            data: avgData
          }
        ]
      }

      this.areaChart.setOption(option)
    },
    // 绘制测温区域
    drawRoi(context) {
      let ctx = context
      let isNormal = false

      if (!ctx) {
        isNormal = this.tempShowLocation === 'nrml'
        cv.imshow('canvas', this.getZoomMat(this.canvasMat))
        ctx = this.getCtx()
      }

      for (let i = 0; i < this.drawList.length; i++) {
        const draw = this.drawList[i]
        switch (draw.type) {
          // 点
          case 'point':
            {
              if (isNormal) {
                this.drawPoint(ctx, draw.newX, draw.newY, draw.name + '  ' + draw.avg + '℃')
              } else {
                this.drawPoint(ctx, draw.newX, draw.newY, draw.name)
              }
            }
            break

          // 线
          case 'line':
            {
              this.drawLine(ctx, draw.oldX, draw.oldY, draw.newX, draw.newY, draw.result, isNormal, true)
            }
            break

          // 矩形
          case 'rectangle':
            {
              this.drawRct(ctx, draw.oldX, draw.oldY, draw.newX, draw.newY, draw.result, isNormal, true)
            }
            break

          // 圆形
          case 'circle':
            {
              this.drawCircle(ctx, draw.oldX, draw.oldY, this.getRadius(draw.oldX, draw.oldY, draw.newX, draw.newY), draw.result, isNormal, true)
            }
            break

          // 多边形
          case 'polygon':
            {
              this.drawPolygon(ctx, draw.polyXYList, true, draw.result, isNormal, true)
            }
            break

          default:
            break
        }
      }
    },
    // 标记最大最小值
    drawTriangle(ctx, maxX, maxY, minX, minY) {
      maxX = this.getAfVal(maxX)
      maxY = this.getAfVal(maxY)
      minX = this.getAfVal(minX)
      minY = this.getAfVal(minY)

      ctx.beginPath()
      ctx.moveTo(maxX, maxY)
      ctx.lineTo(maxX + this.triSize, maxY - 2 * this.triSize)
      ctx.lineTo(maxX - this.triSize, maxY - 2 * this.triSize)
      ctx.closePath()
      ctx.fillStyle = '#0000ff'
      ctx.fill()

      ctx.beginPath()
      ctx.moveTo(minX, minY)
      ctx.lineTo(minX + this.triSize, minY + 2 * this.triSize)
      ctx.lineTo(minX - this.triSize, minY + 2 * this.triSize)
      ctx.closePath()
      ctx.fillStyle = '#ff0000'
      ctx.fill()
    },
    // 绘制点
    drawPoint(ctx, offsetX, offsetY, detail) {
      offsetX = this.getAfVal(offsetX)
      offsetY = this.getAfVal(offsetY)

      // 绘制点
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, 2, 0, Math.PI * 2)
      ctx.fillStyle = this.fontColor
      ctx.fill()

      // 绘制文字
      ctx.font = `${this.fontSize}px "微软雅黑"`
      ctx.fillText(detail, offsetX, offsetY + 10)
    },
    // 绘制线
    drawLine(ctx, oldX, oldY, newX, newY, result, isNormal, needZoom) {
      if (needZoom) {
        oldX = this.getAfVal(oldX)
        oldY = this.getAfVal(oldY)
        newX = this.getAfVal(newX)
        newY = this.getAfVal(newY)
      }

      ctx.beginPath()
      ctx.moveTo(oldX, oldY)
      ctx.lineTo(newX, newY)
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, newX, newY + this.fontSize)
        if (isNormal) {
          let count = 2
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, newX, newY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, newX, newY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, newX, newY + this.fontSize * count)
          }
        }
      }
    },
    // 绘制矩形
    drawRct(ctx, oldX, oldY, newX, newY, result, isNormal, needZoom) {
      if (needZoom) {
        oldX = this.getAfVal(oldX)
        oldY = this.getAfVal(oldY)
        newX = this.getAfVal(newX)
        newY = this.getAfVal(newY)
      }

      ctx.beginPath()
      ctx.rect(oldX, oldY, newX - oldX, newY - oldY)
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        const textX = (newX + oldX) / 2
        const textY = (newY + oldY) / 2

        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, textX, textY + this.fontSize)
        if (isNormal) {
          let count = 2
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, textX, textY + this.fontSize * count)
          }
        }
      }
    },
    // 绘制圆形
    drawCircle(ctx, centerX, centerY, radius, result, isNormal, needZoom) {
      if (needZoom) {
        centerX = this.getAfVal(centerX)
        centerY = this.getAfVal(centerY)
        radius = this.getAfVal(radius)
      }

      ctx.beginPath()
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, centerX, centerY)
        if (isNormal) {
          let count = 1
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, centerX, centerY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, centerX, centerY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, centerX, centerY + this.fontSize * count)
          }
        }
      }
    },
    // 绘制多边形
    drawPolygon(ctx, polyXYList, isEnd, result, isNormal, needZoom) {
      const xyList = JSON.parse(JSON.stringify(polyXYList))

      if (needZoom) {
        xyList.map((item) => {
          item.x = this.getAfVal(item.x)
          item.y = this.getAfVal(item.y)
        })
      }

      ctx.beginPath()
      ctx.moveTo(xyList[0].x, xyList[0].y)
      for (let i = 1; i < xyList.length; i++) {
        ctx.lineTo(xyList[i].x, xyList[i].y)
      }
      if (isEnd) {
        ctx.closePath()
      }
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        const xList = xyList.map((item) => {
          return item.x
        })
        const yList = xyList.map((item) => {
          return item.y
        })

        const textX = (Math.max(...xList) + Math.min(...xList)) / 2
        const textY = (Math.max(...yList) + Math.min(...yList)) / 2

        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, textX, textY)
        if (isNormal) {
          let count = 1
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, textX, textY + this.fontSize * count)
          }
        }
      }
    },
    // 鼠标点击
    mouseClick(e) {
      if (!this.isHaveImg) return
      this.offsetX = this.getBefVal(e.offsetX)
      this.offsetY = this.getBefVal(e.offsetY)
      switch (this.currentFunct) {
        case 'point':
          {
            const ctx = this.getCtx()

            // 获取文字描述
            const name = 'P' + this.pointCount

            // 获取温度
            this.roiAndtempList[name] = []
            for (let i = 0; i < this.paramList.length; i++) {
              const { pTempBufferDst, cProType, iwidth } = this.paramList[i]
              this.roiAndtempList[name].push(getPointTemp(this.offsetX, this.offsetY, pTempBufferDst, cProType, iwidth))
            }

            const temp = this.roiAndtempList[name][0].avg

            // 绘制文字
            if (this.tempShowLocation === 'nrml') {
              this.drawPoint(ctx, this.offsetX, this.offsetY, name + '  ' + temp + '℃')
            } else {
              this.drawPoint(ctx, this.offsetX, this.offsetY, name)
            }

            // 计数器——点
            this.pointCount++

            this.drawList.push({
              type: 'point',
              name,
              max: temp,
              min: temp,
              avg: temp,
              newX: this.offsetX,
              newY: this.offsetY
            })
          }
          break

        case 'polygon':
          {
            // 已经开始绘制
            if (this.startFlag) {
              cv.imshow('canvas', this.newMat)
            } else {
              this.startFlag = true
              this.newMat = this.getNewMat()
            }

            const ctx = this.getCtx()
            this.polygonXYList.push({
              x: e.offsetX,
              y: e.offsetY
            })
            this.drawPolygon(ctx, this.polygonXYList)
          }
          break

        default:
          break
      }
    },
    // 鼠标按下
    mouseDown(e) {
      if (!this.isHaveImg) return
      // 控制左键触发
      if (e.button !== 0) return
      this.offsetX = e.offsetX
      this.offsetY = e.offsetY
      switch (this.currentFunct) {
        case 'line':
          {
            this.newMat = this.getNewMat()
            this.startFlag = true
          }
          break

        case 'rectangle':
          {
            this.newMat = this.getNewMat()
            this.startFlag = true
          }
          break

        case 'circle':
          {
            this.newMat = this.getNewMat()
            this.startFlag = true
          }
          break

        default:
          break
      }
    },
    // 获取当前的画布数据
    getNewMat() {
      const canvas = document.getElementById('canvas')

      if (canvas) {
        const ctx = canvas.getContext('2d')
        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        return cv.matFromImageData(imgData)
      } else {
        this.$message.error('获取画布失败')
      }
    },
    // 获取缩放前的值
    getBefVal(val) {
      return parseInt(val / this.zoom)
    },
    // 获取缩放后的值
    getAfVal(val) {
      return parseInt(val * this.zoom)
    },
    // 缩放结果
    getZoomMat(srcMat) {
      const dstMat = new cv.Mat()
      cv.resize(srcMat, dstMat, new cv.Size(), this.zoom, this.zoom, cv.INTER_LANCZOS4)
      return dstMat
    },
    // 滑轮滚动
    mouseWheel(e) {
      let changeFlag = false

      if (e.deltaY > 0 && this.zoom > 0.25) {
        // 下滚 缩小
        this.zoom -= 0.25
        changeFlag = true
      } else if (e.deltaY < 0 && this.zoom < 1.75) {
        // 上滚 放大
        this.zoom += 0.25
        changeFlag = true
      }

      if (changeFlag) {
        this.drawRoi()

        const mainImg = document.getElementById('main-img').getBoundingClientRect()
        const outWidth = mainImg.width
        const outHeight = mainImg.height

        const relateDiv = document.getElementById('relate-div').getBoundingClientRect()
        const inWidth = relateDiv.width
        const inHeight = relateDiv.height

        this.minWidth = outWidth > inWidth ? '100%' : inWidth + 'px'
        this.minHeight = outHeight > inHeight ? '100%' : inHeight + 'px'
      }
    },
    // 鼠标滑动
    mouseMove(e) {
      if (!this.isHaveImg) return

      switch (this.currentFunct) {
        case 'line':
          {
            if (this.startFlag) {
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              this.drawLine(ctx, this.offsetX, this.offsetY, e.offsetX, e.offsetY)
            }
          }
          break

        case 'rectangle':
          {
            if (this.startFlag) {
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              this.drawRct(ctx, this.offsetX, this.offsetY, e.offsetX, e.offsetY)
            }
          }
          break

        case 'circle':
          {
            if (this.startFlag) {
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)

              this.drawCircle(ctx, this.offsetX, this.offsetY, this.getRadius(this.offsetX, this.offsetY, e.offsetX, e.offsetY, true))
            }
          }
          break

        default:
          break
      }
    },
    // 鼠标抬起
    mouseUp(e) {
      if (!this.isHaveImg) return

      const isNormal = this.tempShowLocation === 'nrml'
      const beforeStartX = this.getBefVal(this.offsetX)
      const beforeStartY = this.getBefVal(this.offsetY)
      const beforeEndX = this.getBefVal(e.offsetX)
      const beforeEndY = this.getBefVal(e.offsetY)

      switch (this.currentFunct) {
        case 'line':
          {
            this.startFlag = false
            const ctx = this.getCtx()
            cv.imshow('canvas', this.newMat)

            const name = 'L' + this.linCount

            // 获取温度
            this.roiAndtempList[name] = []
            for (let i = 0; i < this.paramList.length; i++) {
              const { pTempBufferDst, cProType, iwidth, iheight } = this.paramList[i]
              this.roiAndtempList[name].push(
                getLineTemp(beforeStartX, beforeStartY, beforeEndX, beforeEndY, pTempBufferDst, cProType, iwidth, iheight)
              )
            }
            const result = { ...this.roiAndtempList[name][0], name }

            this.drawLine(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

            this.linCount++
            this.drawList.push({
              name,
              type: 'line',
              oldX: beforeStartX,
              oldY: beforeStartY,
              newX: beforeEndX,
              newY: beforeEndY,
              result
            })
          }
          break

        case 'rectangle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)

              const name = 'R' + this.rctCount

              // 获取温度
              this.roiAndtempList[name] = []
              for (let i = 0; i < this.paramList.length; i++) {
                const { pTempBufferDst, cProType, iwidth, iheight } = this.paramList[i]
                this.roiAndtempList[name].push(
                  getRctTemp(beforeStartX, beforeStartY, beforeEndX, beforeEndY, pTempBufferDst, cProType, iwidth, iheight)
                )
              }
              const result = { ...this.roiAndtempList[name][0], name }

              this.drawRct(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

              this.rctCount++
              this.drawList.push({
                name,
                type: 'rectangle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result
              })
            }
          }
          break

        case 'circle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const radius = this.getRadius(beforeStartX, beforeStartY, beforeEndX, beforeEndY)

              const name = 'C' + this.circleCount

              // 获取温度
              this.roiAndtempList[name] = []
              for (let i = 0; i < this.paramList.length; i++) {
                const { pTempBufferDst, cProType, iwidth } = this.paramList[i]
                this.roiAndtempList[name].push(getCircleTemp(beforeStartX, beforeStartY, radius, pTempBufferDst, cProType, iwidth))
              }
              const result = { ...this.roiAndtempList[name][0], name }

              this.drawCircle(ctx, beforeStartX, beforeStartY, radius, result, isNormal, true)

              this.circleCount++
              this.drawList.push({
                name,
                type: 'circle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result
              })
            }
          }
          break

        default:
          break
      }
    },
    // 鼠标移出
    mouseOut(e) {
      if (!this.isHaveImg) return

      const isNormal = this.tempShowLocation === 'nrml'
      const beforeStartX = this.getBefVal(this.offsetX)
      const beforeStartY = this.getBefVal(this.offsetY)
      const beforeEndX = this.getBefVal(e.offsetX)
      const beforeEndY = this.getBefVal(e.offsetY)

      switch (this.currentFunct) {
        case 'line':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)

              const name = 'L' + this.linCount

              // 获取温度
              this.roiAndtempList[name] = []
              for (let i = 0; i < this.paramList.length; i++) {
                const { pTempBufferDst, cProType, iwidth, iheight } = this.paramList[i]
                this.roiAndtempList[name].push(
                  getLineTemp(beforeStartX, beforeStartY, beforeEndX, beforeEndY, pTempBufferDst, cProType, iwidth, iheight)
                )
              }
              const result = { ...this.roiAndtempList[name][0], name }

              this.drawLine(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

              this.linCount++
              this.drawList.push({
                name,
                type: 'line',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result
              })
            }
          }
          break

        case 'rectangle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)

              const name = 'R' + this.rctCount

              // 获取温度
              this.roiAndtempList[name] = []
              for (let i = 0; i < this.paramList.length; i++) {
                const { pTempBufferDst, cProType, iwidth, iheight } = this.paramList[i]
                this.roiAndtempList[name].push(
                  getRctTemp(beforeStartX, beforeStartY, beforeEndX, beforeEndY, pTempBufferDst, cProType, iwidth, iheight)
                )
              }
              const result = { ...this.roiAndtempList[name][0], name }

              this.drawRct(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

              this.rctCount++
              this.drawList.push({
                name,
                type: 'rectangle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result
              })
            }
          }
          break

        case 'circle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const radius = this.getRadius(beforeStartX, beforeStartY, beforeEndX, beforeEndY)

              const name = 'C' + this.circleCount

              // 获取温度
              this.roiAndtempList[name] = []
              for (let i = 0; i < this.paramList.length; i++) {
                const { pTempBufferDst, cProType, iwidth } = this.paramList[i]
                this.roiAndtempList[name].push(getCircleTemp(beforeStartX, beforeStartY, radius, pTempBufferDst, cProType, iwidth))
              }
              const result = { ...this.roiAndtempList[name][0], name }

              this.drawCircle(ctx, beforeStartX, beforeStartY, radius, result, isNormal, true)

              this.circleCount++
              this.drawList.push({
                name,
                type: 'circle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result
              })
            }
          }
          break

        default:
          break
      }
    },
    // 鼠标右击
    contextMenu() {
      if (!this.isHaveImg) return
      const isNormal = this.tempShowLocation === 'nrml'

      switch (this.currentFunct) {
        case 'polygon':
          {
            // 结束绘制
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)

              this.polygonXYList.map((item) => {
                item.x = this.getBefVal(item.x)
                item.y = this.getBefVal(item.y)
              })

              const name = 'G' + this.polyCount

              // 获取温度
              this.roiAndtempList[name] = []
              for (let i = 0; i < this.paramList.length; i++) {
                const { pTempBufferDst, cProType, iwidth, iheight } = this.paramList[i]
                this.roiAndtempList[name].push(getPolyTemp(this.polygonXYList, pTempBufferDst, cProType, iwidth, iheight))
              }
              const result = { ...this.roiAndtempList[name][0], name }

              this.drawPolygon(ctx, this.polygonXYList, true, result, isNormal, true)

              this.polyCount++
              this.drawList.push({
                name,
                type: 'polygon',
                polyXYList: JSON.parse(JSON.stringify(this.polygonXYList)),
                result
              })

              this.polygonXYList = []
            }
          }
          break

        default:
          break
      }
    },

    // 求半径
    getRadius(oldX, oldY, newX, newY, needZoom) {
      let radius = Math.sqrt(Math.pow(newX - oldX, 2) + Math.pow(newY - oldY, 2))
      const width = needZoom ? this.params.iwidth * this.zoom : this.params.iwidth
      const height = needZoom ? this.params.iheight * this.zoom : this.params.iheight

      // 判断是否越界
      // 左越界
      if (oldX - radius < 0) {
        radius = oldX
      }
      // 右越界
      if (oldX + radius > width) {
        radius = width - oldX
      }
      // 上越界
      if (oldY - radius < 0) {
        radius = oldY
      }
      // 下越界
      if (oldY + radius > height) {
        radius = height - oldY
      }

      return radius
    },
    // 切换功能
    functClick(funct) {
      if (!this.isHaveImg) return
      this.currentFunct = funct
    },
    // 导出单图报告
    getSingleWord() {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          for (let i = 0; i < this.paramList.length; i++) {
            const param = this.paramList[i]
            // 获取base64的图片
            let imageIR = undefined,
              imageLight = undefined
            const canvas = document.createElement('canvas')

            // 红外
            const mIRMat = cv.matFromArray(param.iheight, param.iwidth, cv.CV_8UC1, param.mIRBuffer)
            cv.cvtColor(mIRMat, mIRMat, cv.COLOR_GRAY2BGR)
            cv.imshow(canvas, mIRMat)
            this.drawRoi(canvas.getContext('2d'))
            imageIR = canvas.toDataURL()

            // 可见光
            if (param.lightBuffer.length > 0) {
              const mLightMat = cv.matFromImageData(jpeg.decode(param.lightBuffer))
              cv.imshow(canvas, mLightMat)
              imageLight = canvas.toDataURL()
            }

            // 区域信息
            const roiList = []
            for (const name in this.roiAndtempList) {
              const { max, min, avg } = this.roiAndtempList[name][i]
              roiList.push({ name, max: max + '℃', min: min + '℃', avg: avg + '℃' })
            }

            // 处理环境参数
            const { sSerialName, iwidth, iheight, fReflectTemp, fAirTemp, fHumidity, fEmiss, fDistance, fileName } = param
            const params = {
              sSerialName,
              iwidth,
              iheight,
              fReflectTemp: fReflectTemp - 273.15 + '℃',
              fAirTemp: fAirTemp - 273.15 + '℃',
              fHumidity,
              fEmiss,
              fDistance: fDistance + 'M',
              fileName
            }

            exportWord(params, { imageIR, imageLight, roiList, remark: value || '' })
          }
        })
        .catch(() => {})
    },
    // 导出组合报告
    getCombineWord() {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          const irList = [],
            roiList = [],
            roiChartList = []

          // 获取base64的图片
          const canvas = document.createElement('canvas')
          // 获取图表
          const chartDiv = document.getElementById('exportChart')

          const option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['MAX', 'MIN', 'AVG']
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: '{value}℃'
              },
              scale: true
            }
          }

          // 循环文件参数获取红外图像
          for (let i = 0; i < this.paramList.length; i++) {
            const param = this.paramList[i]

            // 红外
            const mIRMat = cv.matFromArray(param.iheight, param.iwidth, cv.CV_8UC1, param.mIRBuffer)
            cv.cvtColor(mIRMat, mIRMat, cv.COLOR_GRAY2BGR)
            cv.imshow(canvas, mIRMat)
            this.drawRoi(canvas.getContext('2d'))
            irList.push({ imageIR: canvas.toDataURL() })
          }

          // 循环区域与温度对应关系
          const getRoiAndTemp = []
          for (const name in this.roiAndtempList) {
            getRoiAndTemp.push(() => {
              const maxData = [],
                minData = [],
                avgData = []

              // 获取区域与温度值
              for (let j = 0; j < this.roiAndtempList[name].length; j++) {
                const { max, min, avg } = this.roiAndtempList[name][j]
                // 区域信息
                roiList.push({ name, fileName: this.paramList[j].fileName, max, min, avg })

                // 图表数据
                maxData.push(max)
                minData.push(min)
                avgData.push(avg)
              }

              // 图表图片
              option.series = [
                {
                  name: 'MAX',
                  type: 'line',
                  data: maxData
                },
                {
                  name: 'MIN',
                  type: 'line',
                  data: minData
                },
                {
                  name: 'AVG',
                  type: 'line',
                  data: avgData
                }
              ]
              if (!this.exportChart || this.exportChart.isDisposed()) {
                this.exportChart = echarts.init(chartDiv)
              }

              return new Promise((resolve) => {
                this.exportChart.setOption(option)
                // 绘制完成
                this.exportChart.on('finished', () => {
                  roiChartList.push({ roiChart: this.exportChart.getDataURL(), roiName: name })
                  this.exportChart.dispose()
                  resolve()
                })
              })
            })
          }

          // 开始执行
          let i = 0
          const getval = async () => {
            if (i >= getRoiAndTemp.length) {
              // 处理环境参数
              const { sSerialName, iwidth, iheight, fReflectTemp, fAirTemp, fHumidity, fEmiss, fDistance, fileName } = this.paramList[0]
              const params = {
                sSerialName,
                iwidth,
                iheight,
                fReflectTemp: fReflectTemp - 273.15,
                fAirTemp: fAirTemp - 273.15,
                fHumidity,
                fEmiss,
                fDistance,
                fileName
              }

              exportWord(params, { irList, roiList, roiChartList, remark: value || '' }, 2)
            } else {
              await getRoiAndTemp[i]()
              i++
              getval()
            }
          }

          getval()
        })
        .catch(() => {})
    },
    // csv
    getCsv() {
      // 懒加载该用法
      import('@/utils/Export2Excel').then((excel) => {
        // 构造数据
        const data = [
          ['参数：', '反射温度：', this.params.fReflectTemp],
          ['', '大气环境温度：', this.params.fAirTemp],
          ['', '大气透过率：', this.params.fHumidity],
          ['', '发射率：', this.params.fEmiss],
          ['', '距离：', this.params.fDistance],
          ['', '']
        ]

        let firstFlag = true
        for (let j = 0; j < this.params.iheight; j++) {
          // 单独处理第一行
          const index = 6 + j
          if (j === 0) {
            data[index] = ['帧1']
          } else {
            data[index] = ['']
          }
          // 高 下标
          data[index].push(j)
          for (let k = 0; k < this.params.iwidth; k++) {
            // 宽 下标
            if (firstFlag) {
              data[5].push(k)
            }

            // 温度
            data[index].push(getPointTemp(k, j, this.pTempBufferDst, this.params.cProType, this.params.iwidth).avg)
          }
          firstFlag = false
        }

        // 调用我们封装好的方法进行导出Excel
        excel.export_json_to_excel({
          // 导出的内容
          data,
          // 导出的文件名称
          filename: '测温数据',
          // 导出的表格宽度是否自动
          autoWidth: true,
          // 导出文件的后缀类型
          bookType: 'xlsx'
        })
      })
    },
    // 获取画布
    getCtx() {
      const canvas = document.getElementById('canvas')

      if (canvas) {
        return canvas.getContext('2d')
      } else {
        this.$message.error('获取画布失败')
      }
    },
    // 读取图片
    fileChange(e) {
      // 重置数据
      this.drawList = []

      const reader = new FileReader()
      reader.readAsArrayBuffer(e.target.files[0])
      reader.onload = (e) => {
        const list = new Uint8Array(e.target.result)
        // 整合图像信息
        this.integrateImgInfo(getParams({ isGW: false, irgU8Array: list, fileName: e.target.files[0].name }), true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
}
#main-top {
  position: absolute;
  left: 25%;
  top: 0;
  width: 50%;
}
#main-img {
  height: 90%;
  width: 95%;
  position: absolute;
  top: 5%;
  left: 2%;
  overflow: auto;
  .content {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
}
#exportChart {
  position: absolute;
  bottom: -230%;
}
.info-aside {
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2);
  border-radius: 8px;
  background: white;
  ::v-deep .el-input__inner {
    height: 40px;
  }
  .export-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-evenly;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
.el-divider__text {
  font-weight: bold;
}
.el-divider--horizontal {
  background: #5053dd;
  margin: 24px auto;
  width: 90%;
}
.button-img {
  width: 30px;
  height: 30px;
  padding: 6px 8px;
  cursor: pointer;
}
.aside-chart {
  height: calc((100% - 100px) / 2);
}
.back-span {
  font-size: 12px;
  color: #5053dd;
  cursor: pointer;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 15px 0;
}
::v-deep .el-form-item__content {
  margin-right: 20px;
  box-sizing: border-box;
}
</style>
